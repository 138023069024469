import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout-home"
import SEO from "../components/seo"
import Polygon from "../images/polygon_shape.png"
// import VRScene from "../components/vr"

const Home = () => (
  <Layout>
    <SEO title="Home" />
    <div className="flex-container">
      <div className="menu-grid">
        <div className="code">
          <Link to="/code/" className="menu-btn">Code</Link>
        </div>
        <div className="skills">
          {/* <Link to="./skills/" className="menu-btn">Skills</Link> */}
        </div>
        <div className="media">
          {/* <Link to="./media/" className="menu-btn">Media</Link> */}
        </div>
        <div className="shape">
          <img src={ Polygon } />
          {/* <VRScene className="vr-scene" /> */}
        </div>
        <div className="history">
          {/* <Link to="./history/" className="menu-btn">History</Link> */}
        </div>
        <div className="design">
          {/* <Link to="./design/" className="menu-btn">Design</Link> */}
        </div>
        <div className="contact">
          <Link to="/contact/" className="menu-btn">Contact</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default Home
